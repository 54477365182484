import styled from 'styled-components';

export const Container = styled.div`
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;

  #meet {
    width: 100dvw;
    height: 100dvh;
  }

  iframe {
    width: 100dvw !important;
    height: 100dvh !important;
  }
`;
